import React from 'react';
import styled from "styled-components";
import Dropdown from './dropdown';
import { Link } from 'react-router-dom';

const NavigationContainer = styled.div`
    position: relative;
    text-align: center;
`

const NavArray = styled.ul`
    display: inline-flex;
    list-style: none;
`

const NavElement = styled.li`
    margin-right: 1.5em;
    font-weight: 300;
    font-size: 1.5em;
`

export const Hyperlink = styled(Link)`
    color: var(--text);
    text-decoration: none;
    padding: 10%;

    :hover {
        background-color: var(--text);
        transition: background-color 0.2s;
        color: var(--background);
        border-radius: 0.5rem;
    }
`

function Navbar() {
    const links = [
        { name: 'Home', url: '/' },
        { name: 'Articles', url: '/articles' },
    ]
    return (
        <>
            <NavigationContainer>
                <NavArray>
                    {links.map((link) => (
                        <NavElement>
                            <Hyperlink to={link.url} key={link.name}>{link.name}</Hyperlink>
                        </NavElement>
                    ))}
                    <NavElement><Dropdown/></NavElement>
                </NavArray>
            </NavigationContainer>
        </>
    );
}

export default Navbar;