import React from 'react';
import Socialmedia from './socialmedia';
import Spotify from './spotify';
import styled from "styled-components";
import axios from 'axios';
import URL from '../../api/endpoints';
import ReactTooltip from 'react-tooltip';
import { useEffect, useState } from 'react';
import { ActivityStatus } from '../../types/activityStatus';
import { getColorByStatus } from '../../util/statusColor';

function CalcAge() {
    let birthday = new Date('09/30/2001')
    let today = new Date()

    let agee = today.getFullYear() - birthday.getFullYear();
    const month = today.getMonth() - birthday.getMonth();
  
    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) agee--;
  
    return agee;
}

const Container = styled.div`
  margin-right: 5em;
`

const MyName = styled.div`
  font-weight: bold;
  font-size: 36px;
`

// #39C758 rgba(51, 217, 178, 0.7);
const Status = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${props => props.hex};
  border-radius: 30px;
  transform: scale(1);
  margin-left: 0.1em;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(${props => (props.rgb.toString())}, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
  }
`

const AboutMe = styled.div`
  margin-top: 1.5em;
  font-size: 18px;
`

function Details() {
  const [status, setStatus] = useState<ActivityStatus>();

  const fetchStatus = async () => {
    try {
      axios.get(URL.fetchActivityStatus)
            .then(function (response) {
              console.log(response.data)

              // let obj: ActivityStatus = {active: response.data.active, status: response.data.status}
              let obj = getColorByStatus(response.data.status)
              setStatus(obj)
              ReactTooltip.rebuild()
            })
            .catch(function (error) {
              console.log(error);
            });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchStatus();
  }, [])

  return (
      <> 
        <Container>
          <MyName>Jakub Szturomski {status && <Status data-tip={status?.status.toString()} hex={status?.color?.hex} rgb={status?.color?.rgb}/>}</MyName>

          <AboutMe>Hi there 👋🏼 I'm Kuba, I'm {CalcAge()} years old.</AboutMe>
          <AboutMe>Wannabe hacker, self-taught, software engineer.</AboutMe>

          <Socialmedia/>
          <Spotify/>
        </Container>
        <ReactTooltip />
      </>
  );
}

export default Details;