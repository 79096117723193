import React from "react";
import { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import App from "../../pages/_app";
import styled from 'styled-components';
import rehypeHighlight from "rehype-highlight";
// import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeRaw from 'rehype-raw'

const Root = styled.div`
  display: inline-grid;
  width: 100%;
  justify-content: center;
`

const ArticleContainer = styled.div`
  width: 750px;
  
  a {
    color: #686de0;
    text-decoration: none;
    font-weight: 700;
    transition: color .2s,border-bottom-color .2s;
    border-bottom: 2px solid transparent;
  }

  a:hover {
    border-bottom-color: #FFC107;
  }

`

const SourceCodeSyntax = styled(SyntaxHighlighter)`
  font-size: 13px;
`

function MarkdownArticle() {
    const [markdown, setstate] = useState('')

    useEffect(() => {
          import(`./../../posts/`+'ig'+`.md`)
              .then(res => {
                  fetch(res.default)
                      .then(res => res.text())
                      .then(res => setstate(res));
              })
              .catch(err => console.log(err));
      });
  
    return App(
    <Root>
      <ArticleContainer>
          <ReactMarkdown
            children={markdown}
            // rehypePlugins={[rehypeHighlight]}
            rehypePlugins={[rehypeRaw]}
            components={{
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SourceCodeSyntax
                    children={String(children).replace(/\n$/, '')}
                    style={dracula}
                    language={match[1]}
                    PreTag="div"
                    // {...props}
                  />
                ) : (
                  <SourceCodeSyntax
                    children={String(children).replace(/\n$/, '')}
                    style={dracula}
                    language={'php'}
                    PreTag="div"
                    // {...props}
                  />
                )
              }
            }}
          />
      </ArticleContainer>
    </Root>
    );
  }
   
export default MarkdownArticle;