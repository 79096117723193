import App from "../_app";
import { useEffect } from "react";
import LoginAdmin from "../../components/admin/login";

export default function AdminPage() {

    const getCurrentUser = () => {

    }

    useEffect(() => {
        getCurrentUser();
    }, []);

    return App(
        <LoginAdmin/>
    )
}