export default class SpotifyModel {
    listening: boolean
    name: string
    image: string
    artist: string
    external_url: string
    progress: number
    current_time: number
    total_time: number

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.listening = source["listening"]
        this.name = source["name"];
        this.image = source["image"];
        this.artist = source["artist"];
        this.external_url = source["external_url"];
        this.progress = source["progress"];
        this.current_time = source["current_time"];
        this.total_time = source["total_time"];
    }
}