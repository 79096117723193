
export default class RepositoryModel {
    id: number
    name: string
    description: string
    url: string
    language: string //change to enum with color of the circle
    stars: number

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.name = source["name"];
        this.description = source["description"];
        this.url = source["html_url"];
        this.language = source["language"];
        this.stars = source["stargazers_count"]
    }
}