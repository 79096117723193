import App from "../../_app";
import styled from 'styled-components';

const Root = styled.div`
    h1 {
        margin-left: 2em;
        text-decoration-line: underline;
        margin-bottom: 2em;
    }
`

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    .interactiveContainer {
        display: block;
        margin-left: 5em;
    }
`

const HeadersArea = styled.textarea`
    width: 529px;
    height: 473px;
    background: #EFEFEF;
`

function parseHeaders(json) {
    console.log(document.getElementsByTagName('textarea')[0].value)
    let headers = ''
    let headersAreaElement = document.getElementsByTagName('textarea')[0];
    let lines = headersAreaElement.value?.split(/\n/);
    let pattern = document.getElementsByTagName('input')[0].value
    
    const headersMap = new Map()

    lines?.forEach(e => {
      let arr = e.split(/: /);
      let hName = arr[0];
      let hVal = arr[1];
      headersMap.set(hName, hVal);
    });

  headersMap.forEach(function(value, key) {
    if (json) {
      headers = JSON.stringify(Object.fromEntries(headersMap));
    } else {
      headers += pattern?.replace("$name", key).replace("$val", value) + "\n";
    }
  })

    headersAreaElement.value = headers
}

export default function HeadersConverter() {
    return App(
        <Root>
            <h1>Headers Converter</h1>

            <Container>
                <HeadersArea/>

                <div className="interactiveContainer">

                    <p>Enter your pattern:</p>
                    <input type="text" placeholder="dupa"/>
                    <p>Example: httpGet.addHeader(“$name”, “$val”)</p>

                    <div>
                        <p>Convert as:</p>
                        <button onClick={() => parseHeaders(true)}>JSON</button>
                        <button onClick={() => parseHeaders(false)}>Convert</button>
                    </div>
                </div>
            </Container>
        </Root>
    )
    
}