import React from 'react';
import Homepage from './pages/homepage';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";
import Blog from './pages/blog';
import MarkdownArticle from './components/blog/markdownArticle';
import { createGlobalStyle } from 'styled-components'
import HeadersConverter from './pages/tools/headers_converter';
import AdminPage from './pages/admin';


const GlobalStyle = createGlobalStyle`
  body {
    background: var(--background);
    font-family: 'Roboto';
    font-weight: 300;
    color: var(--text);
    padding: 0;
    margin: 0;
    height: 1000px;
  }

  html, body {
    padding: 0;
    margin: 0;
  }

  * {
  box-sizing: border-box;
  transition: all 150ms linear;
  }

  :root {
    --text: #ffffff;
    --light-widget-background: #333;
    --background: #181a1b;
  }
`

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/admin" element={<AdminPage />}/>
          <Route path="/article/:id" element={<MarkdownArticle />} />
          <Route path="/articles" element={<Blog />} />
          <Route path="/tools" element={<HeadersConverter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
