import { useEffect, useState } from 'react';
import axios from 'axios';
import URL from '../../api/endpoints';
import SpotifyModel from '../../types/spotifyModel'
import styled from "styled-components";

const Container = styled.a<{ visible: boolean }>`
    position: absolute;
    width: 350px;
    height: 100px;
    background: var(--light-widget-background);
    border-radius: 20px;
    box-sizing: content-box;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 29px 0px;
    visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
    display: ${({ visible }) => (visible ? "flex" : "none")};

    color: var(--text);
    text-decoration: none;

    :hover {
      filter: brightness(85%);
    }
`

const SpotifyImage = styled.img`
    width: 90px;
    height: 90px;
    left: 0px;
    top: 0px;
    bottom: 0px;
`

const ProgressBar = styled.div`
    position: absolute;
    width: 100%;
    height: 10px;
    background-color: #717171;
    bottom: 0;
    border-top: 1px solid black;
`

const Fill = styled.div`
    position: absolute;
    width: ${props => props.progress}%;
    height: 10px;
    background-color: #4CAF50;
`

const SpotifyData = styled.div`
    position: absolute;
    left: 100px;
    top: 15px;
`

const SongTitle = styled.div`

`

const SongAuthor = styled.div`
    margin-top: 0.4em;
    font-size: 12px;
`

const SongTime = styled.div`
    position: absolute;
    bottom: 15px;
    left: 100px;
    font-size: 12px;
`

function msToHMS(duration:number){
  let mins = Math.floor(duration/(1000*60))%60
  let secs = Math.floor(duration/1000)%60

  return (mins < 10 ? "0" + mins : mins) + ":" + (secs < 10 ? "0" + secs : secs);
}

export default function Spotify() {
    const [song, setSong] = useState<SpotifyModel>();


    const fetchSpotifyWebsocket = () => {
      const ws = new WebSocket("wss://localhost:2137/websocket");

      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        let obj = new SpotifyModel(json)
        setSong(obj)
      }
    }

    const fetchSpotify = async () => {
        try {
          axios.get(URL.spotifyFetchSong)
            .then(function (response) {
              let obj = new SpotifyModel(response.data)
              setSong(obj)
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      }

    useEffect(() => {
        fetchSpotifyWebsocket()

        setInterval(() => {
          console.log('debugg')
          if (song != undefined) {
            // progress_percent := progress_ms.(float64) / duration_ms.(float64) * 100

          }
        }, 1000)
    }, [])

    return (
        <>
        <Container visible={song?.listening} href={song?.external_url}>
            <SpotifyImage src={song?.image}/>
            <ProgressBar>
              <Fill progress={song?.progress.toString()} />
            </ProgressBar>

            <SpotifyData>
             <SongTitle>{song?.name ? song.name : 'error'}</SongTitle>
             <SongAuthor>{song?.artist ? song.artist : 'error'}</SongAuthor>
            </SpotifyData>

            <SongTime>{msToHMS(song?.current_time as number)} / {msToHMS(song?.total_time as number)}</SongTime>
          </Container>
        </>
    )
}