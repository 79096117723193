import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    display: inline-block;
`

const Content = styled.div`
    display: none;
    position: absolute;
    font-size: 15px;
    min-width: 160px;
    background-color: var(--background);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
        color: var(--text);
        opacity: 0.3;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    
    a:hover {
        color: var(--text);
        opacity: 1;
    }
`

const ContentHover = styled.div`
    &:hover ${Content} {
        display: block;
    }
`

function Dropdown(props) {

    return (
        <Container>
            <ContentHover>Tools
                <Content>
                    <Link to="/tools">Headers converter</Link>
                </Content>
            </ContentHover>
        </Container>
    );
}

export default Dropdown;