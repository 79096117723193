const themes = {
    dark: {
      text: "#ffffff",
      background: "#181a1b",
      'light-widget-background': "#333"
    },
    light: {
      text: "#000000",
      background: "#ffffff",
      'light-widget-background': "#f2f2f2"
    },
  };
  
  export function SetTheme(theme_name: "light" | "dark") {
    const theme = themes[theme_name];
    for (const key in theme)
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
    localStorage.setItem("theme-name", theme_name);
  }
  
  export function ToggleTheme(): string {
    const current_theme = localStorage.getItem("theme-name") || "light";
    SetTheme(current_theme === "light" ? "dark" : "light");
    return current_theme === "light" ? "dark" : "light";
  }