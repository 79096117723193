import axios from "axios";
import URL from "../api/endpoints";
import { useState, useEffect } from "react";
import { Icon } from '@iconify/react';
import { ToggleTheme } from "../util/theme";
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';

const FooterStyle = styled.footer`
    position: fixed;
    left: 0.5em;
    bottom: 0;
    margin: 0 0 1em 1em;
    font-size: 20px;
`


function Footer() {

    // Hooks
    const [bitcoin, setBitcoin] = useState(null);

    // Actions
    const fetchCurrency = async () => {
        try {
            axios.get(URL.cryptocurrencyUrl)
                .then(function (response) {
                    let price = response.data['market_data']['current_price']['usd']
                    setBitcoin(price)
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCurrency()
    })

    const handleClick = () => {
        let a = ToggleTheme()
    }

    return (
        <>
            <FooterStyle>
                <span>kuba.sh • 2022 •</span> <a href="#" onClick={handleClick} data-tip="click me!"><Icon icon="carbon:moon" color="var(--text)"/></a>
            </FooterStyle>
            <ReactTooltip/>
        </>
    )
}

export default Footer;