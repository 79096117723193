import styled from 'styled-components';
import { useEffect } from "react";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
`

const Panel = styled.div`
    margin-top: 5em;
    width: 524px;
    height: 385px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 29px 0px;

    background: var(--light-widget-background);
    border-radius: 45px;
`

const LabelContainer = styled.div`
    display: block;
    margin: 0 auto;
    width: 65%;
    
    h1 {
        text-align: center;
    }
`

const Input = styled.input`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin-bottom: 1.5em;
`

const Checkbox = styled.label`
    input {
        margin-bottom: 1.5em;
        height: 20px;
        width: 20px;
    }

    span {
        font-size: 19px;
    }
`

const LoginButton = styled.button`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin-bottom: 1.5em;
    background-color: var(--background);
    color: var(--text)
`

export default function LoginAdmin() {

    return (
        <>
        <Container>
            <Panel>
                <LabelContainer>
                    <h1>kuba.sh • admin</h1>
                    <Input type="text" name="" id="" placeholder="Enter login" />
                    <Input type="password" name="" id="" placeholder="Enter password"/>
                    <Checkbox>
                        <input type="checkbox"/>
                        <span>Remember me</span>
                    </Checkbox>
                    <LoginButton>Login</LoginButton>
                </LabelContainer>
            </Panel>
        </Container>
        </>
    )
}