import { Icon } from '@iconify/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import URL from '../../api/endpoints';
import RepositoryModel from '../../types/repositoryModel';
import styled from "styled-components";

const ALLOWED_REPOS = ['goCalendar', 'dotfiles', 'hackintosh-efi', 'ms-teams-autojoiner']

const RepositoriesTitle = styled.div`
    margin-top: 5em;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    color: var(--text);
`

const Container = styled.a`
    width: 400px;
    height: 120px;
    background: var(--light-widget-background);
    border-radius: 20px;
    margin-right: 2em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 29px 0px;
    color: var(--text);
    text-decoration: none;

    :hover {
      filter: brightness(85%);
    }

    :last-child {
      margin-right: 0;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5em;
    width: auto;
`

const RepositoryTitle = styled.div`
    font-size: 15px;
    margin: 1em 0 0 1em;
`

const RepositoryDescription = styled.div`
    margin: 0.4em 0 0 1em;
`

const RepositoryStatistics = styled.div`
    display: inline-flex;
    margin: 1em 0 0 1em;
`

const RepositoryLanguageIcon = styled.div`
    width: 11px;
    height: 11px;
    background: #50B2D1;
    border-radius: 20px;
    margin-right: 5px;
    margin-top: 3px;
`

const RepositoryLanguage = styled.div`
    margin-right: 10px;
`

function sliceIntoChunks(arr: any[], chunkSize: number) {
  const res = Array<any>([]);
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const CreateRows = (arr: RepositoryModel[]) => {
  let chunked = sliceIntoChunks(arr, 2)

  const singleRow = (chunkedArr) => {

    return (
      <Row>
        {chunkedArr.map(e => {
          return GenerateRepository(e)
        })}
      </Row>
    )
  }

  return (
    <>
      {chunked.map(e => {
        return singleRow(e)
      })}
    </>
  )
}

const GenerateRepository = ((obj: RepositoryModel) => {
  return (
    <Container href={obj.url}>
      <RepositoryTitle>{obj.name}</RepositoryTitle>

      <RepositoryDescription>{obj.description}</RepositoryDescription>

      <RepositoryStatistics>
        <RepositoryLanguageIcon/>

        <RepositoryLanguage>{obj.language}</RepositoryLanguage>

        <Icon icon="dashicons:star-empty" color="var(--text)"/>{obj.stars}
      </RepositoryStatistics>
    </Container>
  )
})

export default function Repositories() {
  const [repositories, setRepositories] = useState<RepositoryModel[]>([]);

  const fetchRepositories = async () => {
    let arrRepos = Array<RepositoryModel>()
    try {
      axios.get(URL.githubFetchRepositories)
        .then(function (response) {
          response.data.map(e => {
            let repository = new RepositoryModel(e)
            if (ALLOWED_REPOS.includes(repository.name)) {
              arrRepos.push(repository)
            }
          })

          setRepositories(arrRepos)
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchRepositories()
  }, [])

  return (
    <>
      <RepositoriesTitle>Featured Repositories</RepositoriesTitle>
        {CreateRows(repositories)}
    </>
  )
}