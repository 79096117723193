import { createGlobalStyle } from 'styled-components'
import styled from "styled-components";
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import { useState, useEffect } from 'react';
import { SetTheme } from '../util/theme';

const GlobalStyle = createGlobalStyle`
body {
  background: var(--background);
  font-family: 'Roboto';
  font-weight: 300;
  color: var(--text);
  padding: 0;
  margin: 0;
  height: 1000px;
}

html, body {
  padding: 0;
  margin: 0;
}

* {
box-sizing: border-box;
transition: all 150ms linear;
}

:root {
  --text: #ffffff;
  --light-widget-background: #333;
  --background: #181a1b;
}
`

const Page = styled.div`
  margin-bottom: 100px;
`

export default function App(element: JSX.Element) {
    useEffect(() => {
        if (localStorage.getItem('theme-name')) SetTheme(localStorage.getItem('theme-name') as 'light' | 'dark');
    }, []);


    return (
        <>
          <Navbar/>

          {element}
    
          <Footer/>
          <GlobalStyle/>
          <Page/>
        </>
        );
}