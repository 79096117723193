import React from 'react';
import styled from "styled-components";
import Details from '../../components/homepage/details';
import Repositories from '../../components/homepage/repository';
import App from '../_app';

const Avatar = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 29px 0px;
`

const Container = styled.div`
  display: flex;
  margin-top: 8rem;
  justify-content: center;
`

function Homepage() {
    return App(
    <>
      <Container>
        <Details/>
        <Avatar src="./pic.jpg"/>
      </Container>

      <Repositories/>
    </>
    );
}

export default Homepage;
