import App from "../_app";
import styled from 'styled-components'
import { BlogArticle } from "../../types/blogArticle";

const Root = styled.div`

`

const HeadingTitle = styled.h1`
    margin-left: 2em;
    text-decoration-line: underline;
    margin-bottom: 2em;
`

const Container = styled.div`
  display: block;
  width: 75%;
  margin: 0 auto;
`

const Row = styled.div`
    display: inline-block;
    display: -webkit-inline-box;
    width: 100%;
    margin-bottom: 1em;
    // margin-left: 8em;
`

const Article = styled.a`
  display: inline-block;
  width: 300px;
  margin-right: 3em;
  color: var(--text);
  text-decoration: none;

  img {
    width: 296px;
    height: 155px;
  }

  // .background {
  //   display: inline-block;
  //   height: 100px;
  //   background: #333;
  // }

  .background > h2 {
    font-size: 19px;
  }
`

function getArticle(article: BlogArticle) {
  return (
    <Article href={article.fileName}>
                  <img src={article.thumbnail}/>
                  
                  <div className="background">
                    <h2>{article.title}</h2>
                  </div>
    </Article>
  )
  
}

function Blog() {
    const article: BlogArticle = {id: 1, title: 'Instagram - Reverse engineering of UUID algorithm', thumbnail: 'https://www.91-cdn.com/hub/wp-content/uploads/2020/12/Instagram.jpg', fileName: '/article/1'};

    return App(
        <Root>
          <HeadingTitle>Featured Articles</HeadingTitle>
            <Container>
              <Row>
                {getArticle(article)}
              </Row>
            </Container>
        </Root>
      );
}

export default Blog;