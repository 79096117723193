import React from "react";
import { Icon } from '@iconify/react';
import styled from "styled-components";
import ReactTooltip from 'react-tooltip';

const Container = styled.ul`
    display: inline-flex;
    padding: 0;
    list-style: none;
    font-size: 30px;
`

const Element = styled.li`
    margin-right: 0.3em;
`

function Socialmedia() {

  const color = 'var(--text)'

  const profiles = [
        {icon: 'akar-icons:github-outline-fill', url: 'https://github.com/nxm', tooltip: '@nxm'},
        {icon: 'iconoir:twitter', url: 'https://twitter.com/kuba_x86'},
        {icon: 'akar-icons:instagram-fill', url: 'https://instagram.com/x86', tooltip: '@x86'},
        {icon: 'akar-icons:linkedin-fill', url: 'https://linkedin.com/in/nxm/', tooltip: '@nxm'},
        {icon: 'ant-design:mail-outlined', url: 'mailto:contact@kuba.sh'},
        {icon: 'akar-icons:discord-fill', tooltip: 'darwin#0850'},
        {icon: 'akar-icons:telegram-fill', tooltip: '@darwin_kernel'},
    ]

  return (
        <>
          <Container>
            {profiles.map((profile) => (
              <Element>
                <a data-tip={profile.tooltip} href={profile.url}>
                <Icon icon={profile.icon} color={color} />
                </a>
              </Element>
            ))}
          </Container>
          <ReactTooltip />
        </>
      );
}

export default Socialmedia;