import { ActivityStatus, StatusColor } from "../types/activityStatus";

export function getColorByStatus(statusName:string) {
    let obj: ActivityStatus = {status: statusName}

    switch (statusName) {
        case "online":
            obj.color = {hex: '#39C758', rgb: [51, 217, 178]};
            break;
        case "dnd":
            obj.color = {hex: '#e83d4d', rgb: [227, 77, 66]}
            break;
        case "idle":
            obj.color = {hex: '#f9ca24', rgb: [247, 208, 67]}
            break;
    
        default:
            obj.status = 'offline'
            obj.color = {hex: '#e83d4d', rgb: [227, 77, 66]}
            break;
    }

    return obj
}